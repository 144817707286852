"use client";

import { useEffect } from "react";
import { useParams, useRouter } from "next/navigation";
import { v4 as uuidv4 } from "uuid";

export default function ChangeParams() {
  const router = useRouter();

  useEffect(() => {
    const uuid = uuidv4();
    const download = sessionStorage.getItem("download") || "false";

    if (download === "true") {
      sessionStorage.removeItem("download");
      router.replace(`/${uuid}`);
    }
  }, []);

  return null;
}
