"use client";

import { useDownloadFormContext } from "@/providers/DownloadFormProvider";
import { Button } from "./ui/Button";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { IImage, useStateValue } from "@/providers/StateProvider";
import { useRouter } from "next/navigation";
import axios from "axios";
import { BASE_URL } from "@/config/config";
import { cn } from "@/lib/utils";

async function getImage(imageUrl: string) {
  const response = await axios.post(`${BASE_URL}/api/freepik`, {
    imageUrl,
  });

  const { data, status } = response;

  if (!data || status !== 200) {
    let message = data?.message;
    if ((data.message = "getaddrinfo ENOTFOUND img.freepik.com"))
      message = "Please check internet connection and try again";

    throw new Error(message || "failed to fetch");
  }

  if (data.status !== "success") {
    throw new Error(data.message);
  }

  return data.data;
}

export function DownloadDelay() {
  const router = useRouter();
  const { setData } = useStateValue();
  const { isButtonEnabled, timer, downloadUrl, setDownloadUrl } =
    useDownloadFormContext();
  const { mutate, isPending } = useMutation({
    mutationFn: getImage,
    mutationKey: ["getImage"],
    onSuccess: (data) => {
      toast.success(
        "image fetched successfully, redirecting you to the download page"
      );

      setData(data as IImage[]);
      router.push("/download");
    },
    onError: (error: any) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  function handleClick() {
    if (downloadUrl === null) return;

    const toastId = toast.loading("preparing your image");
    mutate(downloadUrl, {
      onSettled: () => toast.dismiss(toastId),
      onSuccess: () => {
        setDownloadUrl(null);
        router.push("/download");
      },
    });
  }

  return (
    <section id="download__delay" className="grid place-content-center">
      <Button
        onClick={handleClick}
        disabled={!isButtonEnabled || isPending}
        className={cn(!downloadUrl && "hidden")}
      >
        {isButtonEnabled ? "Get Image" : `Wait ${timer}s`}
      </Button>
    </section>
  );
}
