"use client";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useRouter } from "next/navigation";
import { useStateValue, IImage } from "@/providers/StateProvider";
import { BASE_URL } from "@/config/config";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDownloadFormContext } from "@/providers/DownloadFormProvider";

const schema = z.object({
  imageUrl: z
    .string({
      required_error: "Please enter a valid url",
    })
    .url("Please enter a valid url")
    .trim()
    .min(1, "image url can't be empty")
    .startsWith("https://img.freepik.com", {
      message: "Please enter a valid freepik image url",
    }),
});

export function Form() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      imageUrl: "",
    },
  });
  const downloadFormContext = useDownloadFormContext();

  async function onSubmit({ imageUrl }: z.infer<typeof schema>) {
    document
      .getElementById("download__delay")
      ?.scrollIntoView({ behavior: "smooth" });

    downloadFormContext.startTimer();
    downloadFormContext.setDownloadUrl(imageUrl);
    return;
  }

  useEffect(
    function () {
      async function handlePaste() {
        try {
          const imageUrl = await navigator.clipboard.readText();
          setValue("imageUrl", imageUrl);
        } catch (error) {}
      }
      document.addEventListener("paste", handlePaste);

      return function () {
        document.removeEventListener("paste", handlePaste);
      };
    },
    [setValue]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-[100%]">
      <label className=" flex w-full relative max-w-3xl mx-auto border rounded-full p-1 overflow-hidden">
        <input
          {...register("imageUrl")}
          id="imageUrl"
          type="text"
          className="w-full h-9 sm:h-10 outline-none border-none px-3 bg-background rounded-full"
          placeholder="Enter freepik image url "
        />
        <button
          type="submit"
          title="Download"
          className="absolute right-1 bg-primary text-primary-foreground px-3 py-2 rounded-full flex justify-center items-center"
        >
          <span className="hidden sm:inline-block">Download</span>
          <span role="img" className="inline-block sm:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 16"
              height="20"
              width="20"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              ></path>
            </svg>
          </span>
        </button>
      </label>
      {errors.imageUrl?.message && (
        <p className="text-red-500 text-sm text-center hidden md:block mt-2">
          {errors.imageUrl.message}
        </p>
      )}
    </form>
  );
}
